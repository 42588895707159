@font-face {
  font-family: 'Pragmatica';
  src: url('../public/fonts/Pragmatica-Bold.woff2') format('woff2'),
    url('../public/fonts/Pragmatica-Bold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Pragmatica';
  src: url('../public/fonts/Pragmatica_Book.woff2') format('woff2'),
    url('../public/fonts/Pragmatica_Book.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Pragmatica';
  src: url('../public/fonts/Pragmatica-Black.woff2') format('woff2'),
    url('../public/fonts/Pragmatica-Black.woff') format('woff');
  font-weight: 800;
}
@font-face {
  font-family: 'Pragmatica';
  src: url('../public/fonts/Pragmatica_Extended-Black.woff2') format('woff2'),
    url('../public/fonts/Pragmatica_Extended-Black.woff') format('woff');
  font-weight: 900;
}
@font-face {
  font-family: 'Pragmatica';
  src: url('../public/fonts/Pragmatica_Light.woff2') format('woff2'),
    url('../public/fonts/Pragmatica_Light.woff') format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Pragmatica';
  src: url('../public/fonts/Pragmatica_Extra-Light.woff2') format('woff2'),
    url('../public/fonts/Pragmatica_Extra-Light.woff') format('woff');
  font-weight: 100;
}
@font-face {
  font-family: 'Pragmatica';
  src: url('../public/fonts/Pragmatica-Medium.woff2') format('woff2'),
    url('../public/fonts/Pragmatica-Medium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'PTMono';
  src: url('../public/fonts/PTMono-Regular.woff2') format('woff2'),
    url('../public/fonts/PTMono-Regular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'PTMono';
  src: url('../public/fonts/PTMono-Bold.woff2') format('woff2'),
    url('../public/fonts/PTMono-Bold.woff') format('woff');
  font-weight: 700;
}
